import { Box, VStack } from '@chakra-ui/react';
import { ColumnFiltersState, createColumnHelper, Row } from '@tanstack/react-table';
import { produce, WritableDraft } from 'immer';
import _ from 'lodash';
import React from 'react';
import { useImmerReducer } from 'use-immer';

import { useGetPersonnelBySearchQuery } from '@/API/personnel.api';
import AvailablePreviewList from '@/components/available-preview-list/AvailablePreviewList';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setViewAccessSelectedPersonnelIds } from '@/store/slices/viewAccess.slice';
import { PersonnelItemSlim } from '@/types/personnel.types';

// Key to sort items on the table
const PERSONNEL_SORT_KEY = 'id';

enum ViewEditorPersonnelColumns {
  displayName = 'displayName',
  expired = 'expired',
  personnelId = 'personnelId',
  personnelTypeId = 'personnelTypeId',
}

interface UIState {
  selectedPersonnelTypes: number[];
}

// ToDo: Expand UIState into this
interface TableState {
  availableItemsColumnFilters: ColumnFiltersState;
  isHydrated: boolean;
  isLoading: boolean;
  pendingChanges: PersonnelItemSlim[];
  uiState: UIState;
}

enum ReducerActionTypes {
  SELECT_PERSONNEL_TYPES,
  SET_AVAILABLE_ITEMS_COLUMN_FILTERS,
  SET_IS_HYDRATED,
  SET_IS_LOADING,
}

type ReducerAction = { type: ReducerActionTypes; payload: unknown };

const stateReducer = (draft: TableState, action: ReducerAction) => {
  switch (action.type) {
    case ReducerActionTypes.SET_IS_HYDRATED: {
      draft.isHydrated = action.payload as boolean;
      break;
    }
    case ReducerActionTypes.SELECT_PERSONNEL_TYPES: {
      draft.uiState.selectedPersonnelTypes = action.payload as number[];
      break;
    }
    case ReducerActionTypes.SET_AVAILABLE_ITEMS_COLUMN_FILTERS: {
      draft.availableItemsColumnFilters = action.payload as ColumnFiltersState;
      break;
    }
    case ReducerActionTypes.SET_IS_LOADING: {
      draft.isLoading = action.payload as boolean;
      break;
    }
  }
};

const ViewAccessPersonnel = (): React.JSX.Element => {
  const { selectedViewData: view } = useAppSelector((state) => state.viewPage);
  const { personnelSearchFilter, selectedDepartmentIds, selectedPersonnelTypeIds, selectedPersonnelIDs } =
    useAppSelector((state) => state.viewAccess);

  const dispatch = useAppDispatch();

  const [state, localDispatch] = useImmerReducer(stateReducer, {
    availableItemsColumnFilters: [],
    isHydrated: false,
    isLoading: true,
    pendingChanges: [],
    uiState: {
      selectedPersonnelTypes: [],
    },
  });

  const { data: availableAccessPersonnel } = useGetPersonnelBySearchQuery(
    {
      DepartmentIds: selectedDepartmentIds,
      OrderByLastName: 'asc',
      SearchQuery: personnelSearchFilter,
      Slim: true,
    },
    { skip: !selectedDepartmentIds.length && !personnelSearchFilter },
  );

  const { data: currentAccessPersonnel } = useGetPersonnelBySearchQuery(
    {
      OrderByLastName: 'asc',
      PersonnelIds: view?.accessibleBy || [],
      Slim: true,
    },
    { skip: state.isHydrated || !(view?.accessibleBy ?? []).length },
  );

  // Once the personnel data are loaded, we don't want to re-fetch them
  React.useEffect(() => {
    if (currentAccessPersonnel && !state.isHydrated) {
      localDispatch({ payload: true, type: ReducerActionTypes.SET_IS_HYDRATED });
      dispatch(setViewAccessSelectedPersonnelIds(currentAccessPersonnel.map((personnel) => personnel.id)));
    }
  }, [localDispatch, currentAccessPersonnel, dispatch, state.isHydrated]);

  // When the selected personnel items change, we need to update the table data
  const { availableItems, previewItems } = React.useMemo(() => {
    const previewItems = new Map<number, PersonnelItemSlim>();
    const availableItems = new Map<number, PersonnelItemSlim>();

    if (!availableAccessPersonnel && !currentAccessPersonnel) {
      return {
        availableItems,
        previewItems,
      };
    }

    if (selectedDepartmentIds.length || personnelSearchFilter) {
      availableAccessPersonnel?.forEach((personnel) => {
        if (!selectedPersonnelIDs.includes(personnel.id)) {
          availableItems.set(personnel.id, personnel);
        } else {
          previewItems.set(personnel.id, personnel);
        }
      });
    }

    currentAccessPersonnel?.forEach((personnel) => {
      if (selectedPersonnelIDs.includes(personnel.id)) {
        previewItems.set(personnel.id, personnel);
      } else {
        availableItems.set(personnel.id, personnel);
      }
    });

    return {
      availableItems,
      previewItems,
    };
  }, [
    availableAccessPersonnel,
    currentAccessPersonnel,
    personnelSearchFilter,
    selectedDepartmentIds.length,
    selectedPersonnelIDs,
  ]);

  const updateColumnFilter = React.useCallback(
    (id: ViewEditorPersonnelColumns, value: unknown, draft: WritableDraft<TableState>) => {
      const filterIdx = (state.availableItemsColumnFilters as ColumnFiltersState).findIndex(
        (filter) => filter.id === id,
      );

      // eslint-disable-next-line no-magic-numbers
      if (filterIdx === -1) {
        draft.availableItemsColumnFilters.push({
          id,
          value,
        });
      } else {
        if (!value) {
          // eslint-disable-next-line no-magic-numbers
          draft.availableItemsColumnFilters.splice(filterIdx, 1);
          return;
        }
        draft.availableItemsColumnFilters[filterIdx] = {
          id,
          value,
        };
      }
    },
    [state.availableItemsColumnFilters],
  );

  const personnelTypeFilterFn = (row: Row<PersonnelItemSlim>, _id: string, filterValue: number[]) => {
    if (!filterValue.length) return true;

    const personnelTypeId = row.getValue(ViewEditorPersonnelColumns.personnelTypeId);

    return filterValue.includes(personnelTypeId as number);
  };

  personnelTypeFilterFn.autoRemove = (val: string) => !val;

  const columnHelper = createColumnHelper<PersonnelItemSlim>();

  const availableItemsColumns = [
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.personnelName.display, {
      cell: undefined,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorPersonnelColumns.displayName,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.pTypeId, {
      cell: undefined,
      enableHiding: true,
      filterFn: personnelTypeFilterFn,
      id: ViewEditorPersonnelColumns.personnelTypeId,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.expired, {
      cell: undefined,
      enableHiding: true,
      filterFn: 'equals',
      id: ViewEditorPersonnelColumns.expired,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorPersonnelColumns.personnelId,
    }),
  ];

  const previewColumnsHelper = createColumnHelper<PersonnelItemSlim>();

  const previewColumns = [
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.personnelName.display, {
      cell: undefined,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorPersonnelColumns.displayName,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.pTypeId, {
      cell: undefined,
      enableHiding: true,
      // filterFn: personnelTypeFilterFn,
      id: ViewEditorPersonnelColumns.personnelTypeId,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.expired, {
      cell: undefined,
      enableHiding: true,
      filterFn: 'equals',
      id: ViewEditorPersonnelColumns.expired,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorPersonnelColumns.personnelId,
    }),
  ];

  const handleItemsChanged = React.useCallback(
    (items: PersonnelItemSlim[]) => {
      const personnelIds = items.map(({ id }) => id);

      dispatch(setViewAccessSelectedPersonnelIds(personnelIds));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (!_.isEqual(selectedPersonnelTypeIds, state.uiState.selectedPersonnelTypes)) {
      localDispatch({
        payload: selectedPersonnelTypeIds,
        type: ReducerActionTypes.SELECT_PERSONNEL_TYPES,
      });

      const columnFiltersDraft = produce(state, (draft) => {
        updateColumnFilter(ViewEditorPersonnelColumns.personnelTypeId, selectedPersonnelTypeIds, draft);
      });
      localDispatch({
        payload: [...columnFiltersDraft.availableItemsColumnFilters],
        type: ReducerActionTypes.SET_AVAILABLE_ITEMS_COLUMN_FILTERS,
      });
    }
  }, [localDispatch, selectedPersonnelTypeIds, state, updateColumnFilter]);

  const getNoDataFoundMessage = (): string | undefined => {
    if (!selectedDepartmentIds.length && !personnelSearchFilter) {
      return 'Select one or more departments';
    }
    if (selectedDepartmentIds.length || personnelSearchFilter.length) {
      return 'No personnel found which match the provided filters';
    }

    return undefined;
  };

  return (
    <VStack gap={5} align={'top'}>
      <AvailablePreviewList
        availableColumns={availableItemsColumns}
        availableColumnsVisibility={{
          [ViewEditorPersonnelColumns.personnelTypeId]: false,
          [ViewEditorPersonnelColumns.expired]: false,
          [ViewEditorPersonnelColumns.personnelId]: false,
        }}
        availableItems={availableItems}
        availableItemsColumnFilters={state.availableItemsColumnFilters}
        availableItemsPrimaryColumnId={ViewEditorPersonnelColumns.displayName}
        availableItemsTableLabel={'Available Personnel'}
        availableItemsTableNoDataFoundMessage={getNoDataFoundMessage()}
        availableRowUniqueKey={PERSONNEL_SORT_KEY}
        isOpen={true}
        onItemsChanged={(items: PersonnelItemSlim[]) => handleItemsChanged(items)}
        previewColumns={previewColumns}
        previewColumnsVisibility={{
          [ViewEditorPersonnelColumns.personnelTypeId]: false,
          [ViewEditorPersonnelColumns.expired]: false,
          [ViewEditorPersonnelColumns.personnelId]: false,
        }}
        previewItems={previewItems}
        previewItemsPrimaryColumnId={ViewEditorPersonnelColumns.displayName}
        previewItemsTableLabel={'Selected Personnel'}
        previewRowUniqueKey={PERSONNEL_SORT_KEY}
        primaryAvailableColumnIndex={0}
        primaryPreviewColumnIndex={0}
        showColorPicker={true}
        showSideControls={false}
        tableColumnIds={ViewEditorPersonnelColumns}
      />
    </VStack>
  );
};

export default ViewAccessPersonnel;
