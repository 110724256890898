import React from 'react';

import FilterSelect, { FilterSelectValue } from '@/components/filter-select/FilterSelect';
import { TemplateSlim } from '@/types/template.types';

interface TemplateDropdownProps {
  templateList: TemplateSlim[];
  isLoading?: boolean;
  isRequired?: boolean;
  selectedIds?: number[];
  templateChangeHandler?: (values: number[]) => void;
  templateListCloseHandler?: (values: number[]) => void;
  templateListOpenHandler?: () => void;
}

const TemplatesDropdown = (props: TemplateDropdownProps): JSX.Element => {
  const {
    templateList,
    isLoading = false,
    isRequired = false,
    selectedIds = [],
    templateChangeHandler,
    templateListCloseHandler,
    templateListOpenHandler,
  } = props;

  const [selectedTemplateIds, setSelectedTemplateIds] = React.useState<number[]>(selectedIds);

  const handleTemplatesChange = (values: FilterSelectValue[]) => {
    const templateIds = values.map(({ value }) => value);

    if (templateChangeHandler) {
      templateChangeHandler(templateIds);
    }

    setSelectedTemplateIds(templateIds);
  };

  const handleTemplateListClose = () => {
    if (templateListCloseHandler) {
      templateListCloseHandler(selectedTemplateIds);
    }
  };

  return (
    <FilterSelect
      hasSelectAll={true}
      header={'Templates'}
      inputName={'templates'}
      isLoading={isLoading}
      isMultiSelect={true}
      isRequired={isRequired}
      onChangeHandler={(values) => handleTemplatesChange(values as FilterSelectValue[])}
      onCloseHandler={handleTemplateListClose}
      onOpenHandler={templateListOpenHandler}
      options={templateList}
      optionLabelKey={'templateName'}
      optionValueKey={'templateId'}
      placeHolderText={'Select Template(s)'}
      selectedValues={selectedIds ?? selectedTemplateIds}
    />
  );
};

export default TemplatesDropdown;
