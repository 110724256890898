import { createSlice } from '@reduxjs/toolkit';

import { PersonnelType } from '@/types/personnel.types';

interface ViewAccessState {
  isDepartmentListClosed: boolean;
  isLoading: boolean;
  personnelSearchFilter: string;
  personnelTypes: PersonnelType[];
  publicViewURL: string;
  selectedDepartmentIds: number[];
  selectedPersonnelIDs: number[];
  selectedPersonnelTypeIds: number[];
}

const initialState: ViewAccessState = {
  isDepartmentListClosed: false,
  isLoading: false,
  personnelSearchFilter: '',
  personnelTypes: [],
  publicViewURL: '',
  selectedDepartmentIds: [],
  selectedPersonnelIDs: [],
  selectedPersonnelTypeIds: [],
};

export const ViewAccessSlice = createSlice({
  initialState,
  name: 'viewAccess',
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetViewAccess: (state, _action) => {
      Object.assign(state, initialState);
    },
    setViewAccessIsDepartmentListClosed: (state, action) => {
      state.isDepartmentListClosed = action.payload;
    },
    setViewAccessPersonnelSearchFilter: (state, action) => {
      state.personnelSearchFilter = action.payload;
    },
    setViewAccessPersonnelTypeIds: (state, action) => {
      state.selectedPersonnelTypeIds = action.payload;
    },
    setViewAccessPersonnelTypes: (state, action) => {
      state.personnelTypes = action.payload;
    },
    setViewAccessPublicViewURL: (state, action) => {
      state.publicViewURL = action.payload;
    },
    setViewAccessSelectedDepartmentIds: (state, action) => {
      state.selectedDepartmentIds = action.payload;
    },
    setViewAccessSelectedPersonnelIds: (state, action) => {
      state.selectedPersonnelIDs = action.payload;
    },
  },
});

export const {
  resetViewAccess,
  setViewAccessIsDepartmentListClosed,
  setViewAccessPersonnelSearchFilter,
  setViewAccessPersonnelTypeIds,
  setViewAccessPersonnelTypes,
  setViewAccessPublicViewURL,
  setViewAccessSelectedDepartmentIds,
  setViewAccessSelectedPersonnelIds,
} = ViewAccessSlice.actions;

export default ViewAccessSlice.reducer;
